<template>
<div class="dashboard-container">
    <div class="header-container">
        <h5><font-awesome-icon icon="comments" class="mr-10"/> F.A.Q - Frequently Asked Question</h5>
	</div>
	<hr class="bottom-devider"/>
	<div class="body-container">
        <CListGroup class="mt-20 mb-10">
            <CListGroupItem
                v-for="(item, index) in faqs"
                :key="index"
                href="#"
                class="flex-column align-items-start"
                color="light"
            >
                <h5 class="mb-1">{{ item.question }}</h5>
                <p class="mb-1">
                    {{ item.answer }}
                </p>
            </CListGroupItem>
        </CListGroup>
    </div>
</div>
</template>

<script>
export default {
	names: 'FAQ',
	data () {
		return {
            loading: true,
			faqs: [],
		}
	},
	beforeMount () {
        this.fetchFaqs()
	},
	methods: {
		fetchFaqs () {
			this.loading = true
            this.$store.dispatch({
                type: 'faq/get',
                pagination: {
                    limit: 100
                }
            }).then( res => {
                this.faqs = res.result
                console.log(this.faqs)
            }).finally(() => {
                this.loading = false
            })
		},
	}
}
</script>

<style scoped lang="scss">
.mt-10 {
	margin-top: 10px !important;
}
.mr-10 {
	margin-right: 10px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.ml-10 {
	margin-left: 10px !important;
}
</style>